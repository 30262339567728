import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout"
import Seo from "../../components/Seo";

const CatalogItemPage = () => (
	<Layout>
		<Seo title="Let it Snow!" />

		<h2>Let it Snow!</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST13 Let it Snow! front.jpg"
			alt="Jesse Rivest - Let it Snow! - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Prerelease note</h3>
		<p>
			To be released on December 1, 2022.
		</p>

		<h3>Words from Jesse</h3>
		<p>
			<span className="italic">Let it Snow</span> has been on my Christmas list for a while; actually I think I quite like it because it's not specifically a Christmas song!
			It has an olden-days romantic feeling to it, which I have a soft spot for. I had the original recording from Vaughn Monroe in mind as I produced this;
			I really liked those background singers and the sort of square-swing to the rhythm of the punch line in the chorus.
			As such, I had some fun doing my own backing vocals in my studio, and I really enjoyed the guitar instrumental break—I
			had just received a <span className="bold">The Rock Slide®</span> Ariel Posen model brass slide for my pinky finger (I usually play ring finger) and was stoked to try it out.
			Also, this was my first successful recording of guitar and voice simultaneously using two ribbon mics; I was pretty happy with the result.
		</p>
		<p>
			I hope you dig <span className="italic">Let it Snow</span>;
			please share it if you do!
		</p>
		<h3>Track listing</h3>
		<ol>
			<li>
				Let it Snow (3:28)<br />
				(Written by Sammy Cahn and Jule Styne)<br />
				T0700953929 - © Cahn Music (ASCAP) / Quaytor Productions (ASCAP) / WC Music Corp (ASCAP) 1945<br />
				CA-6P8-22-00005 - © Jesse Rivest 2022 (PPL)
			</li>
		</ol>
		<p>
			This recording: catalog number JRIVEST13, © Jesse Rivest 2022, All Rights Reserved<br />
			UPC/EAN: 0198001967696<br />
			Released: December 1, 2022
		</p>

		<h3>Credits</h3>
		<p>
			Produced, recorded, mixed, and mastered by Jesse Rivest at his Little Bakery Studio in Brasilia, DF, Brazil.<br />
			Song sung by Jesse Rivest.<br />
			Acoustic guitar performed by Jesse Rivest.<br />
			Electric guitar performed by Jesse Rivest.<br />
			Backing vocals performed by Jesse Rivest.
		</p>

		<h3>Special Thanks</h3>
		<p>
			Thanks to Jane Lino for being supportive, always.<br />
			Thanks to Tico de Moraes for his ears and feedback.<br />
		</p>

		<h3>Extras</h3>

		<h4>Back cover</h4>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST13 Let it Snow! back.png"
			alt="Jesse Rivest - Let it Snow! - back cover"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
